<template>
  <div class="redmou">
    <div class="header">
      <div class="logo">
        <!-- <img src="../assets/images/landing/logo.png" width="100%"> -->
      </div>
      <div class="nav">
        <ul>
          <li class="active">Features</li>
          <li>FAQ</li>
          <li>About Us</li>
        </ul>
        <span @click="downIOS">
          <img src="../assets/images/landing/down_icon1.png">
        </span>
        <span @click="downAND">
          <img src="../assets/images/landing/down_icon2.png">
        </span>
      </div>
    </div>
    <div class="banner" :style="bannerHeight">
      <div class="box">
        <div class="txt">
          <img src="../assets/images/landing/banner_txt.png" width="100%">
          <div class="btn">
            <span @click="downIOS">
              <img src="../assets/images/landing/down_icon1.png">
            </span>
            <span @click="downAND">
              <img src="../assets/images/landing/down_icon2.png">
            </span>
            <span>
              Get the app
            </span>
          </div>
        </div>
        <div class="img">
          <img src="../assets/images/landing/banner_img.png" width="100%">
        </div>
      </div>
    </div>
    <div class="banner_foot">
      <ul>
        <li>
          <img src="../assets/images/landing/banner_icon3.png" width="100%">
        </li>
        <li>
          <img src="../assets/images/landing/banner_icon2.png" width="100%">
        </li>
        <li>
          <img src="../assets/images/landing/banner_icon1.png" width="100%">
        </li>
      </ul>
    </div>
    <div class="index_sec1">
      <img src="../assets/images/landing/ser1_bg.png" width="100%">
    </div>
    <div class="index_sec2">
      <div class="list">
        <div class="box">
          <div class="img">
            <img src="../assets/images/landing/sec2_1.png" width="100%">
          </div>
          <div class="txt">
            <img src="../assets/images/landing/sec2_1_txt.png" width="100%">
            <div class="btn">
              <span @click="downIOS">
                <img src="../assets/images/landing/down_icon1.png">
              </span>
              <span @click="downAND">
                <img src="../assets/images/landing/down_icon2.png">
              </span>
              <span>
                Get the app
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="list visible-xs">
        <div class="box">
          <div class="img">
            <img src="../assets/images/landing/sec2_2.png" width="100%">
          </div>
          <div class="txt">
            <img src="../assets/images/landing/sec2_2_txt.png" width="100%">
            <div class="btn">
              <span @click="downIOS">
                <img src="../assets/images/landing/down_icon1.png">
              </span>
              <span @click="downAND">
                <img src="../assets/images/landing/down_icon2.png">
              </span>
              <span>
                Get the app
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="list hidden-xs">
        <div class="box">
          <div class="txt">
            <img src="../assets/images/landing/sec2_2_txt.png" width="100%">
            <div class="btn">
              <span @click="downIOS">
                <img src="../assets/images/landing/down_icon1.png">
              </span>
              <span @click="downAND">
                <img src="../assets/images/landing/down_icon2.png">
              </span>
              <span>
                Get the app
              </span>
            </div>
          </div>
          <div class="img">
            <img src="../assets/images/landing/sec2_2.png" width="100%">
          </div>
        </div>
      </div>
      <div class="list">
        <div class="box">
          <div class="img">
            <img src="../assets/images/landing/sec2_3.png" width="100%">
          </div>
          <div class="txt">
            <img src="../assets/images/landing/sec2_3_txt.png" width="100%">
            <div class="btn">
              <span @click="downIOS">
                <img src="../assets/images/landing/down_icon1.png">
              </span>
              <span @click="downAND">
                <img src="../assets/images/landing/down_icon2.png">
              </span>
              <span>
                Get the app
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="index_sec3">
      <h5>Customer Review </h5>
      <ul>
        <li>
          <img src="../assets/images/landing/sec3_1.png" width="100%">
        </li>
        <li>
          <img src="../assets/images/landing/sec3_2.png" width="100%">
        </li>
        <li>
          <img src="../assets/images/landing/sec3_3.png" width="100%">
        </li>
        <li>
          <img src="../assets/images/landing/sec3_4.png" width="100%">
        </li>
        <li>
          <img src="../assets/images/landing/sec3_5.png" width="100%">
        </li>
        <li>
          <img src="../assets/images/landing/sec3_6.png" width="100%">
        </li>
      </ul>
    </div>
    <div class="index_sec4" id="down">
      <div class="box">
          <div class="img">
            <img src="../assets/images/landing/sec4_img.png" width="100%">
          </div>
          <div class="txt">
            <h5>
              Download the App to
              <br/>
              Get Started.
            </h5>
            <div class="btn">
              <p @click="downIOS">
                <img src="../assets/images/landing/sec4_ios.png">
                <span>
                  <i>Download on the</i>
                  <b>APP Store</b>
                </span>
              </p>
              <p @click="downAND">
                <img src="../assets/images/landing/sec4_a.png">
                <span>
                  <i>Download on the</i>
                  <b>Google Play</b>
                </span>
              </p>
            </div>
            <ul>
              <li>
                <img src="../assets/images/landing/sec4_icon1.png" width="60">
                <span>Trustworthy</span>
              </li>
              <li>
                <img src="../assets/images/landing/sec4_icon2.png" width="60">
                <span>Safe</span>
              </li>
              <li>
                <img src="../assets/images/landing/sec4_icon3.png" width="60">
                <span>Fair</span>
              </li>
              <li>
                <img src="../assets/images/landing/sec4_icon4.png" width="60">
                <span>Professional</span>
              </li>
            </ul>
          </div>
      </div>
    </div>
    <foot></foot>
  </div>
</template>

<script>
import Vue from 'vue'
import foot from '@/components/footer'
import {Carousel, CarouselItem, Select, Option, Input, Loading, Message} from 'element-ui' 
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Select)
Vue.use(Option) 
Vue.use(Input)
Vue.use(Loading.directive) 
Vue.prototype.$message = Message
export default {
  name: '',
  components:{
    foot,
  },
  data () {
    return {
      bannerHeight:{
        height: `${window.innerHeight}px`
      },
    }
  },
  computed:{
    // downIOS(){
    //   document.location.href=''
    // },
    // downAND(){
    //   document.location.href=''
    // }
  },
  methods:{

  },
  created(){

  },
  mounted(){

  },
  watch:{

  }
}
</script>

<style lang="less" scoped>
.redmou{
  font-family: PingFang SC;
  .header{
    position: absolute;
    width: 72%;
    top: 5%;
    left: 14%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 999;
    .logo{
      width: 10%;
    }
    .nav{
      display: flex;
      align-items: center;
      ul{
        display: flex;
        align-items: center;
        li{
          margin-right: 35px;
          font-size: 20px;
          font-weight: 600;
          color: #fff;
          cursor: pointer;
          -webkit-transition: all cubic-bezier(.18,1,.21,1) .9s;	
          transition: all cubic-bezier(.18,1,.21,1) .9s;
        }
        li.active{
          color: rgba(79, 182, 246, 1);
          text-decoration: underline;
        }
        li:last-child{
          margin-right: 20px;
        }
      }
      span{
        cursor: pointer;
        margin-left: 20px;
        img{
          display: block;
          width: 42px;
          height: 42px;
        }
      }
    }
  }
  .banner{
    position: relative;
    background: url('../assets/images/landing/banner.png') center no-repeat;
    background-size: cover;
    >img{
      display: block;
    }
    .box{
      width: 72%;
      position: absolute;
      bottom: 15%;
      left: 14%;
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      .txt{
        width: 42%;
        >img{
          width: 100%;
          display: block;
        }
        .btn{
          margin-top: 10%;
          display: flex;
          align-items: center;
          span{
            margin-right: 15px;
            font-size: 20px;
            font-weight: 400;
            color: rgba(79, 182, 246, 1);
            img{
              width: 42px;
              display: block;
            }
          }
        }
      }
      .img{
        width: 42%;
        -webkit-transform: translateY(20%);
        transform: translateY(20%);
        >img{
          width: 100%;
          display: block;
        }
      }
    }
  }
  .banner_foot{
    width: 100%;
    background-color: #02091a;
    padding: 0 14%;
    ul{
      display: flex;
      justify-content: space-between;
      -webkit-transform: translateY(-30%);
      transform: translateY(-30%);
      li{
        flex: 1;
      }
      li:nth-child(2){
        margin: 0 5%;
      }
    }
  }
  .index_sec1{
    img{
      display: block;
    }
  }
  .index_sec2{
    padding: 5% 14%;
    background-color: #02091a;
    .list{
      margin-bottom: 5%;
      .box{
        display: flex;
        align-items: center;
      }
      .img{
        flex: 1;
        display: block;
      }
      .txt{
        flex: 1;
        text-align: left;
        padding: 0 5% 0 10%;
        >img{
          display: block;
        }
        h5{
          font-size: 30px;
          font-weight: 400;
          color: rgba(79, 182, 246, 1);
        }
        p{
          font-size: 16px;
          font-weight: 400;
          color: #FFFFFF;
          line-height: 1.5;
          margin-top: 40px;
          margin-bottom: 40px;
        }
        .btn{
          margin-top: 10%;
          display: flex;
          align-items: center;
          span{
            margin-right: 15px;
            font-size: 20px;
            font-weight: 400;
            color: rgba(79, 182, 246, 1);
            img{
              width: 42px;
              display: block;
            }
          }
        }
      }
    }
    .list:nth-child(2){
      .txt{
        padding-left: 0;
        padding-right: 10%;
      }
    }
  }
  .index_sec3{
    padding: 5% 14%;
    padding-bottom: 2%;
    background: url('../assets/images/landing/sec3_bg.png') center right no-repeat;
    background-size: cover;
    h5{
      font-size: 30px;
      font-weight: 600;
      color: #FFFFFF;
      margin-bottom: 4%;
    }
    ul{
      display: flex;
      flex-wrap: wrap;
      li{
        width: 32%;
        margin-bottom: 3%;
      }
      li:nth-child(3n+2){
        margin: 0 2%;
      }
    }
  }
  .index_sec4{
    padding: 5% 14%;
    background-color: #02091a;
    .box{
      background: url('../assets/images/landing/sec4_bg.png') center no-repeat;
      background-size: 100% 100%; 
      padding: 10% 5% 0 5%;
      display: flex;
      align-items: flex-end;
      .img{
        flex: 1;
        img{
          display: block;
        }
      }
      .txt{
        flex: 1;
        padding-left: 10%;
        text-align: left;
        padding-bottom: 2%;
        h5{
          font-size: 32px;
          font-weight: 600;
          color: #FFFFFF;
          margin-bottom: 10%;
        }
        .btn{
          display: flex;
          align-items: center;
          margin-bottom: 10%;
          p{
            display: flex;
            align-items: center;
            border-radius: 8px;
            padding: 5px 20px;
            cursor: pointer;
            img{
              display: block;
              width: 30px;
              margin-right: 10px;
            }
            span{
              font-size: 12px;
              font-weight: 600;
              color: #FFFFFF;
              i{
                font-style: normal;
                display: block;
                -webkit-transform:scale(.8) translateX(-12px);
                transform:scale(.8) translateX(-12px);
              }
              b{
                display: block;
                font-size: 14px;
              }
            }
          }
          p:first-child{
            background: #000000;
            margin-right: 30px;
          }
          p:last-child{
            background: #A5C945;
          }
        }
        ul{
          display: flex;
          justify-content: space-between;
          li{
            img{
              display: block;
              margin: 0 auto;
            }
            span{
              display: block;
              font-size: 14px;
              font-weight: 500;
              color: #FFFFFF;
              text-align: center;
              margin-top: 5px;
            }
          }
        }
      }
    }
  }
  .foot{
    width: 100%;
    height: 90px;
    position: fixed;
    bottom: 0;
    left: 0;
    background: rgba(51,51,51,0.5);
    z-index: 9;
    .box{
      position: relative;
      .con{
        >p{
          display: inline-block;
          font-size: 13px;
          font-weight: 600;
          color: #FFFFFF;
          width: 200px;
          text-align: center;
          background: linear-gradient(90deg, #1C59BA, #5C9BFF);
        }
        >p:nth-child(1){
          height: 36px;
          line-height: 36px;
          margin-bottom: 10px;
          margin-top: 10px;
        }
        >p:nth-child(3){
          height: 25px;
          line-height: 25px;
        }
        .ewm{
          position: absolute;
          right: 0;
          bottom: 0;
          display: flex;
          align-items: flex-end;
          span{
            font-size: 12px;
            font-weight: 600;
            color: #FFFFFF;
            -webkit-transform: translateY(-10px);
            transform: translateY(-10px);
            cursor: pointer;
          }
          img{
            width: 110px;
          }
        }
        .close{
          position: absolute;
          right: -5%;
          top: 50%;
          img{
            width: 26px;
            margin-top: -13px;
            cursor: pointer;
          }
        }
      }
    }
  }
  .visible-xs{
    display: none !important;
  }
  .hidden-xs{
    display: block !important;
  }
}

@media (min-width: 1200px){
.redmou{
  .index_sec1{
    ul{
      li{
        h5{
          font-size: 26px;
        }
        p{
          font-size: 13px;
        }
      }
    }
  }
  .index_sec4{
    .box{
      .txt{
        h5{
          font-size: 28px;
        }
      }
    }
  }
}
}

@media (min-width: 1600px){
.redmou{
  .index_sec1{
    ul{
      li{
        h5{
          font-size: 30px;
        }
        p{
          font-size: 14px;
        }
      }
    }
  }
  .index_sec4{
    .box{
      .txt{
        h5{
          font-size: 32px;
        }
      }
    }
  }
}
}

@media (max-width: 768px){
  .redmou{
    .header{
    width: 90%;
    left: 5%;
    top: 2%;
    .logo{
      width: 20%;
    }
    .nav{
      ul{
        display: none;
      }
    }
  }
  .banner{
    .box{
      width: 86%;
      flex-wrap: wrap;
      left: 7%;
      bottom: 20%;
      .txt{
        width: 100%;
      }
      .img{
        width: 100%;
        display: none;
      }
    }
  }
  .banner_foot{
    ul{
      flex-wrap: wrap;
      -webkit-transform: translateY(-10%);
      transform: translateY(-10%);
      li{
        width: 100%;
        flex: none;
      }
      li:nth-child(2){
        margin: 0;
      }
    }
  }
  .index_sec1{
    padding: 5% 5%;
    background: url('../assets/images/landing/ser1_bg.png') center no-repeat;
    background-size: cover;
    ul{
      flex-wrap: wrap;
      li{
        width: 100%;
        flex: none;
        padding: 2% 4% !important;
         border-right: none;
        h5{
          font-size: 24px;
          margin-bottom: 20px;
        }
      }
    }
  }
  .index_sec2{
    padding: 5%;
    .list{
      .box{
        flex-wrap: wrap;
      }
      .img{
        width: 100%;
        flex: none;
        margin-bottom: 20px;
      }
      .txt{
        width: 100%;
        flex: none;
        h5{
          font-size: 24px;
        }
        p{
          font-size: 12px;
          margin-top: 20px;
          margin-bottom: 20px;
        }
      }
    }
    .list:nth-child(2){
      .txt{
        padding: 0 5% 0 10%;
      }
    }
  }
  .index_sec3{
    padding: 5%;
    h5{
      font-size: 24px;
      margin-bottom: 2%;
    }
    ul{
      li{
        width: 100%;
      }
      li:nth-child(3n+2){
        margin: 0;
        margin-bottom: 3%;
      }
    }
  }
  .index_sec4{
    padding: 5%;
    .box{
      flex-wrap: wrap;
      .txt{
        width: 100%;
        flex: none;
        padding: 10%;
        h5{
          font-size: 24px;
          margin-bottom: 5%;
          margin-top: 3%;
        }
        .btn{
          flex-wrap: wrap;
          p:first-child{
            margin-bottom: 20px;
          }
        }
        ul{
          li{
            img{
              width: 40px;
            }
            span{
              font-size: 12px;
            }
          }
        }
      }
      .img{
        width: 80%;
        flex: none;
      }
    }
  }
    .foot{
      height: 70px;
      .box{
        .con{
          >p{
            width: 180px;
            font-size: 12px;
          }
          >p:nth-child(1){
            height: 25px;
            line-height: 25px;
            margin-bottom: 6px;
            margin-top: 6px;
          }
          >p:nth-child(3){
            height: 25px;
            line-height: 25px;
          }
          .ewm{
            right: 40px;
            span{
              display: none;
            }
            img{
              width: 90px;
            }
          }
          .close{
            right: 0%;
            img{
              width: 20px;
            }
          }
        }
      }
    }
    .visible-xs{
    display: block !important;
  }
  .hidden-xs{
    display: none !important;
  }
  }
}
</style>

<style lang="less">
.redmou{

}
</style>
