<template>
  <div class="footer">
    <div class="box">
      <div class="top">
        <div class="contact">
          <h5>Contact US</h5>
          <div class="txt">
            <p>
              WhatsApp: +44 7936479723
            </p>
          </div>
        </div>
        <div class="nav">
          <div class="list">
            <h5>Terms & Conditions</h5>
            <p>Site links</p>
            <a href="privacy.html"><p>Privacy Policy</p></a>
          </div>
        </div>
      </div>
			<div class="bottom">
				<p>© 2023 Cardgoal All Rights Reserved.</p>
			</div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  components:{

  },
  data () {
    return {

    }
  },
  computed:{

  },
  methods:{

  },
  created(){

  },
  mounted(){

  },
  watch:{

  }
}
</script>

<style lang="less" scoped>
.footer{
  background: #333740;
  .box{
    width: 80%;
    max-width: 1300px;
    margin: 0 auto;
    .top{
      padding: 40px 0;
      display: flex;
      justify-content: space-between;
      .contact{
        display: flex;
        align-items: center;
        h5{
          font-size: 18px;
          font-weight: 400;
          color: #FFFFFF;
          margin-right: 30px;
        }
        .txt{
          p{
            font-size: 14px;
            font-weight: 500;
            color: #FFFFFF;
            line-height: 40px;
            display: flex;
            align-items: center;
            img{
              width: 16px;
              margin-right: 4px;
            }
          }
        }
      }
      .nav{
        display: flex;
        .list{
          h5{
            font-size: 18px;
            font-weight: 400;
            color: #FFFFFF;
          }
          p{
            font-size: 12px;
            font-weight: 400;
            color: #B3B3B3;
            margin-top: 20px;
          }
        }
        .list:nth-child(1){
          margin-right: 90px;
        }
      }
    }
		.bottom{
			padding: 20px 0;
			border-top: 1px solid #999;
			p{
				text-align: center;
				font-size: 14px;
				font-weight: 500;
				color: #FFFFFF;
			}
		}
  }
}
@media (max-width: 768px){
  .footer{
    .box{
      width: 90%;
      .top{
        flex-wrap: wrap;
        padding: 20px 0;
        .contact{
          width: 100%;
          margin-bottom: 20px;
          h5{
            font-size: 14px;
          }
          .txt{
            p{
              font-size: 12px;
              line-height: 30px;
            }
          }
        }
        .nav{
          flex-wrap: wrap;
          .list{
            width: 100%;
            h5{
              font-size: 14px;
            }
            p{
              margin-top: 10px;
            }
          }
          .list:nth-child(1){
            margin-right: 0px;
            margin-bottom: 20px;
          }
        }
      }
      .bottom{
        padding: 10px 0;
        p{
          font-size: 12px;
        }
      }
    }
  }
}
</style>
